<template>
  <div class="infor_img">
          <div class="bannerBox">
            <div class="swiper-container gallery_top">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="(item, index) of bigImg"
                  :key="index"
                >
                  <img class="gallery_topimg1" :src="item" />
                </div>
              </div>
            </div>
            <div style="height: 12px; width: 400px"></div>
            <div class="swiper-container gallery_thumbs">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide swiper-bottom"
                  v-for="(item, index) of bigImg"
                  :key="index"
                >
                  <div class="gallery_topimg_box">
                    <img class="gallery_topimg" :src="item" />
                    <div class="gallery_topimg_name">相册名称1</div>
                  </div>
                </div>
              </div>
              <div class="swiper-button-prev">
                <img
                  class="prev_left"
                  src="../assets/images/left_current.png"
                  alt=""
                />
              </div>
              <div class="swiper-button-next">
                <img
                  class="prev_right"
                  src="../assets/images/right_current.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
</template>
<script>
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'
import Swiper from "swiper";
import enterpriseCulture from"../assets/images/map_bg_02.jpg";
export default {
  data() {
    return {
      bigImg: [
        enterpriseCulture,
        enterpriseCulture,
        enterpriseCulture,
        enterpriseCulture,
        enterpriseCulture,
      ],
      galleryThumbs: {},
    };
  },
  created() {
    this.$nextTick(function () {
      this.galleryThumbsLunbo();
      this.galleryTopLunbo();
    });
  },
  mounted() {},
  methods: {
    galleryTopLunbo() {
      new Swiper(".gallery_top", {
        spaceBetween: 0,
        loop: false,
        loopedSlides: 5,
        autoplay: true,
        thumbs: {
          //thumbs组件专门用于制作带缩略图的swiper
          swiper: this.galleryThumbs,
          slideThumbActiveClass: "swiper-slide-thumb-active",
        },
      });
    },
    galleryThumbsLunbo() {
      this.galleryThumbs = new Swiper(".gallery_thumbs", {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        autoplay: true,
        spaceBetween: 12, //在slide之间设置距离（单位px）
        slidesPerView: 4, //设置slider容器能够同时显示的slides数量
        loop: false, //设置为true 则开启loop模式
        freeMode: true, //默认为false，普通模式：slide滑动时只滑动一格
        loopedSlides: 7, //一般设置大于可视slide个数2个即可
        watchSlidesVisibility: true, //开启watchSlidesVisibility选项前需要先开启watchSlidesProgress
        watchSlidesProgress: true, //开启这个参数来计算每个slide的progress(进度、进程)
      });
    },
  },
};
</script>
<style scope>
.prev_right,
.prev_left {
  width: 20px;
  height: 20px;
}
.swiper-button-next {
  width: 26px;
  height: 100px;
  background: #000000;
  border-radius: 2px;
  opacity: 0.3;
  right: 0;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.swiper-button-prev {
  width: 26px;
  height: 100px;
  background: #000000;
  border-radius: 2px;
  opacity: 0.3;
  left: 0;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.gallery_topimg_name {
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
}
.gallery_topimg_box {
  position: relative;
  background: #000;
  opacity: 0.8;
}
.gallery_topimg1 {
  width: 100%;
  height: 100%;
}
.gallery_topimg {
  width: 170px;
  height: 100px;
  opacity: 0.6;
  filter: alpha(opacity=80);
}
.bannerBox .gallery_top {
  width: 716px;
  height: 400px;
  border: 1px solid #ccd2e7;
  border-radius: 8px;
}
.bannerBox .gallery_thumbs {
  width: 716px;
  height: 100px;
}
.bannerBox .swiper-slide-thumb-active .gallery_topimg_box .gallery_topimg {
  opacity: 1;
}
.bannerBox .swiper-slide-thumb-active .gallery_topimg_box {
  background: #fff;
  opacity: 1;
}
.bannerBox {
  margin-bottom: 30px;
}
</style>